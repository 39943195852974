import { combineReducers } from 'redux';
import { appReducer } from './appReducer';
import { notificationReducer } from './notificationReducer';
import { reportReducer } from './reportReducer';
import { entityReducer } from '../entities/reducer';
import { requestReducer } from 'requests/reducer';
import { dashboardReducer } from './dashboardReducer';
import { clientDashboardReducer } from './clientDashboardReducer';
import { serviceViewReducer } from './serviceViewReducer';
import { questionnaireFormReducer } from './questionnaireFormReducer';
import { analyticsReducer } from './analyticsReducer';

export default combineReducers({
  appState: appReducer,
  notificationState: notificationReducer,
  reportState: reportReducer,
  entities: entityReducer,
  requests: requestReducer,
  dashboard: dashboardReducer,
  clientDashboard: clientDashboardReducer,
  serviceView: serviceViewReducer,
  questionnaireForm: questionnaireFormReducer,
  analytics: analyticsReducer
});
