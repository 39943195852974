import { merge, mergeWith } from 'lodash-es';
import { normalize } from 'normalizr';
import * as schemas from './schemas';
import { clearSelectorCaches } from './selectors';

const INITIAL_STATE = {};

export const entityReducer = (state = INITIAL_STATE, action) => {
  // We should only handle this action if it involves entities, i.e. if there's a schema
  // defined.
  if (action.props?.schema === undefined) return state;

  let newState;

  // Deletion actions only involve IDs, not full entities, so don't try to normalize
  // the payload.
  const normalized =
    !action.type.startsWith('API_DELETE_') && action.payload && normalize(action.payload, action.props.schema);

  switch (action.type) {
    case 'API_GET_SUCCESS':
    case 'API_POST_SUCCESS':
      newState = { ...state };

      // Make new state for each entity type where the entity would be changed by the action,
      // so that those states will get new references and Redux will notice that entities
      // of that type have changed.
      Object.keys(normalized.entities).forEach((key) => {
        let newSubState = { ...state[key] } || {};
        merge(newSubState, normalized.entities[key]);
        newState[key] = newSubState;
      });
      break;

    case 'API_PUT_SUCCESS':
      newState = { ...state };

      mergeWith(
        newState,
        normalized.entities,
        // Force a new object to be created, so that useSelector will see that it
        // has changed (due to the new reference).
        (obj, source) => ({ ...obj, ...source })
      );
      break;

    case 'API_DELETE_SUCCESS':
      const singularSchema = Array.isArray(action.props.schema) ? action.props.schema[0] : action.props.schema;

      const schemaKey = singularSchema._key;

      let newSlice = { ...state[schemaKey] };

      const idArray = Array.isArray(action.payload) ? action.payload : [action.payload];

      idArray.forEach((id) => delete newSlice[id]);

      // Update the state with the modified slice.
      newState = { ...state, [schemaKey]: newSlice };

      idArray.forEach((id) => deleteDependentChildren(newState, schemaKey, id));

      // Clear the selector caches to avoid deleted entities from showing up via selectors.
      clearSelectorCaches();

      break;

    case 'CLEAR_ENTITIES_FROM_STORE':
      const entityKey = action.props.schema._key;

      newState = {
        ...state,
        [entityKey]: {}
      };

      clearSelectorCaches();
      break;

    default:
      newState = state;
  }

  return newState;
};

// This deletes all entities that should be deleted when their schema dependency is deleted.
// The dependency is defined in the schema by using the parentDependency property. This
// mimics cascade deletion in a database.
const deleteDependentChildren = (mutableState, schemaKey, idToDelete) => {
  Object.values(schemas).forEach((schema) => {
    const schemaDef = schema.schema;

    if (schemaDef.parentDependency?._key === schemaKey && mutableState[schema._key]) {
      Object.values(mutableState[schema._key]).forEach((entity) => {
        if (entity[schemaKey + 'Id'] === idToDelete) {
          delete mutableState[schema._key][entity.id];
        }
      });
    }
  });
};
