//
// Template handlers of common CRUD actions.
//
// These are used by all reducers for CRUD operations.
//

//
// Create
//
export const stateForCreate = {
  createStatus: null,
  createdObject: null,
  createError: null
};

export const makeCreateHandlersFor = (name) => ({
  [`CREATE_${name}_REQUEST`]: (state, action) => ({
    ...state,
    createStatus: 'request',
    createError: null
  }),
  [`CREATE_${name}_SUCCESS`]: (state, action) => ({
    ...state,
    createStatus: 'success',
    createdObject: action.data
  }),
  [`CREATE_${name}_FAILED`]: (state, action) => ({
    ...state,
    createStatus: 'failed',
    createError: action.error
  }),
  [`RESET_CREATE_${name}_ERROR`]: (state, action) => ({
    ...state,
    createError: null
  })
});

//
// Fetch
//

export const stateForFetch = {
  fetchStatus: null,
  fetchedObject: null,
  fetchError: null
};

export const makeFetchHandlersFor = (name) => ({
  [`FETCH_${name}_REQUEST`]: (state, action) => ({
    ...state,
    fetchStatus: 'request',
    fetchError: null
  }),
  [`FETCH_${name}_SUCCESS`]: (state, action) => ({
    ...state,
    fetchStatus: 'success',
    fetchedObject: action.data
  }),
  [`FETCH_${name}_FAILED`]: (state, action) => {
    return {
      ...state,
      fetchStatus: 'failed',
      fetchError: action.error
    };
  },
  [`RESET_FETCH_${name}_ERROR`]: (state, action) => ({
    ...state,
    fetchError: null
  })
});

//
// Update
//

export const stateForUpdate = {
  updateStatus: null,
  updatedObject: null,
  updateError: null
};

export const makeUpdateHandlersFor = (name) => ({
  [`UPDATE_${name}_REQUEST`]: (state, action) => ({
    ...state,
    updateStatus: 'request',
    updateError: null
  }),
  [`UPDATE_${name}_SUCCESS`]: (state, action) => ({
    ...state,
    updateStatus: 'success',
    updatedObject: action.data
  }),
  [`UPDATE_${name}_FAILED`]: (state, action) => {
    return {
      ...state,
      updateStatus: 'failed',
      updateError: action.error
    };
  },
  [`RESET_UPDATE_${name}_ERROR`]: (state, action) => ({
    ...state,
    updateError: null
  })
});

//
// Delete
//

export const stateForDelete = {
  deleteStatus: null,
  deletedObject: null,
  deleteError: null
};

export const makeDeleteHandlersFor = (name) => ({
  [`DELETE_${name}_REQUEST`]: (state, action) => ({
    ...state,
    deleteStatus: 'request',
    deleteError: null
  }),
  [`DELETE_${name}_SUCCESS`]: (state, action) => ({
    ...state,
    deleteStatus: 'success',
    deletedObject: action.data
  }),
  [`DELETE_${name}_FAILED`]: (state, action) => {
    return {
      ...state,
      deleteStatus: 'failed',
      deleteError: action.error
    };
  },
  [`RESET_DELETE_${name}_ERROR`]: (state, action) => ({
    ...state,
    deleteError: null
  })
});
