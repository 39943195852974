import AuthenticationProvider from 'AuthenticationProvider';
import React, { lazy } from 'react';

const Admin = lazy(() => import('./Admin'));

/// Workaround to prevent loading bundle twice at startup
function AdminDashboardWrapper() {
  return (
    <AuthenticationProvider>
      <Admin />
    </AuthenticationProvider>
  );
}

export default AdminDashboardWrapper;
