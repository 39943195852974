export const isNullOrEmptyString = (value) => {
  return value == null || (typeof value === 'string' && value.trim().length === 0);
};

export const formatNumber = (number) => {
  return isNaN(number) || !number ? number : number.toLocaleString('fi-FI');
};

export const HasServiceOrganizationUserWriteRights = (serviceOrganizationUser) => {
  return serviceOrganizationUser?.role === 'Owner' || serviceOrganizationUser?.role === 'Write';
};

/** Returns a new Date with the time set to 23:59:59.999 local time. Other
 *  values are copied from the optional input Date, or the current date. */
export const lastMomentOfDay = (date) => {
  const d = date ? new Date(+date) : new Date();

  d.setHours(23, 59, 59, 999);

  return d;
};

export const formatDate = (date) => {
  if (date === null) {
    return null;
  }

  return new Intl.DateTimeFormat('fi-FI', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }).format(new Date(date));
};

export const formatDateTime = (date) => {
  if (date === null) {
    return null;
  }

  return new Intl.DateTimeFormat('fi-FI', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }).format(new Date(date));
};

export const getCalculatedPositiveDirectionClassName = (positiveDirection) => {
  if (positiveDirection === 'Up') {
    return 'text-success';
  }
  if (positiveDirection === 'Down') {
    return 'text-danger';
  }
  if (positiveDirection === 'Neutral' || positiveDirection === 'Undefined') {
    return '';
  }
};

export const getCalculatedPositiveDirectionColor = (positiveDirection) => {
  if (positiveDirection === 'Up') {
    return '#6bd098';
  }
  if (positiveDirection === 'Down') {
    return '#ef8157';
  }

  return '#212529';
};

export const formatChangeNumber = (number) => {
  if (number === null) {
    return '-';
  }

  if (number > 0) {
    return `+${number}`;
  }

  return number?.toString();
};

export const renderChange = (change) => {
  if (change === null) return '-';
  return formatChangeNumber(change);
};

export const renderValueUnit = (value, unit) => {
  return value === null ? '-' : `${value} ${unit ?? ''}`;
};

export const renderChangeUnit = (value, unit) => {
  if (value === null) return '-';

  return `${formatChangeNumber(value)} ${unit ?? ''}`;
};

export const renderNCount = (value, className) => {
  return <span className={className}>{`(n=${value})`}</span>;
};

export const primaryAnalyticsColor = '#007daa';
