import { useCallback, useEffect, useState } from 'react';
import Keycloak from 'keycloak-js';
import { setAuthContext } from 'auth';
import keycloakConfig from './keycloak-config.json';

const keycloak = new Keycloak(keycloakConfig);

const AuthenticationProvider = ({ children }) => {
  const [authSuccess, setAuthSuccess] = useState(false);

  const initAuth = useCallback(async () => {
    try {
      keycloak.onAuthSuccess = async () => {
        setAuthContext(keycloak);
        setAuthSuccess(true);
      };

      await keycloak.init({
        onLoad: 'login-required',
        checkLoginIframe: false
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (keycloak.authenticated === undefined) initAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!authSuccess) return null;

  return children;
};

export default AuthenticationProvider;
