const INITIAL_STATE = {
  action: null,
  type: null
};

export function notificationReducer(state = INITIAL_STATE, action) {
  // An error will set a notification unless it's explicitly silenced.
  if (action.error && action.notifyError !== false) {
    return {
      action: action,
      type: 'error'
    };
  }

  // Successes set a notification only if explicitly requested.
  if (action.notifySuccess) {
    return {
      action: action,
      type: 'success'
    };
  }

  if (action.type === 'RESET_NOTIFICATION') {
    return INITIAL_STATE;
  }

  return state;
}
