const INITIAL_STATE = {
  answeredQuestions: [], // [ questionId: string, answer: string, questionChoiceId: string]
  previewMode: false, // In preview mode, the questionnaire form is read-only
  invalidQuestionIds: [], // Ids of unanswered questions that must be answered
  skippedQuestionIds: [], // Questions skipped by skip conditions.
  latestAnsweredQuestion: null, // The question which had its answer set most recently.
  questionnaireId: undefined, // The questionnaire currently being answered to.
  initialAnswerTimeCreatedAtMoment: null, // If we are editing answerTime createdAt, this is the moment we are editing from.
  answerTimeCreatedAtMoment: null, // The new answerTime createdAt moment value
  isEditingAnswerTimeAndCreatedAtHasChanged: false
};

export const questionnaireFormReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_QUESTIONNAIRE_FORM_ANSWER':
      const index = state.answeredQuestions.findIndex(
        (answeredQuestion) =>
          answeredQuestion.questionId === action.payload.questionId &&
          (!action.payload.questionChoiceId ||
            answeredQuestion.questionChoiceId === action.payload.questionChoiceId ||
            action.payload.questionType === 'radioField' ||
            action.payload.questionDataType !== 7) // MultiChoice
      );

      if (index >= 0) {
        const newAnsweredQuestions = [...state.answeredQuestions];
        newAnsweredQuestions[index] = action.payload;

        return {
          ...state,
          answeredQuestions: newAnsweredQuestions,
          latestAnsweredQuestion: action.payload
        };
      } else {
        return {
          ...state,
          answeredQuestions: [...state.answeredQuestions, action.payload],
          latestAnsweredQuestion: action.payload
        };
      }

    case 'UNSET_QUESTIONNAIRE_FORM_ANSWER':
      return {
        ...state,
        answeredQuestions: state.answeredQuestions.filter(
          (aq) =>
            aq.questionId !== action.payload.questionId ||
            !action.payload.questionChoiceId ||
            aq.questionChoiceId !== action.payload.questionChoiceId
        )
      };

    case 'SET_QUESTIONNAIRE_FORM_PREVIEW_MODE':
      return { ...state, previewMode: action.payload };

    case 'SET_QUESTIONNAIRE_FORM_INITIAL_ANSWERS':
      return { ...state, answeredQuestions: action.payload };

    case 'RESET_QUESTIONNAIRE_FORM':
      return INITIAL_STATE;

    case 'SET_QUESTIONNAIRE_FORM_INVALID_QUESTION_IDS':
      return { ...state, invalidQuestionIds: action.payload };

    case 'SKIP_QUESTION':
      if (state.skippedQuestionIds.includes(action.payload)) return state;

      return { ...state, skippedQuestionIds: [...state.skippedQuestionIds, action.payload] };

    case 'UNSKIP_QUESTION':
      if (!state.skippedQuestionIds.includes(action.payload)) return state;

      return { ...state, skippedQuestionIds: state.skippedQuestionIds.filter((id) => id !== action.payload) };

    case 'SET_QUESTIONNAIRE_ID':
      return { ...state, questionnaireId: action.payload };

    case 'SET_INITIAL_ANSWERTIME_CREATED_AT':
      return { ...state, answerTimeCreatedAtMoment: action.payload, initialAnswerTimeCreatedAtMoment: action.payload };

    case 'SET_ANSWERTIME_CREATED_AT': {
      const initialDate = state.initialAnswerTimeCreatedAtMoment.format('MMMM Do YYYY, h:mm');
      const newDate = action.payload.format('MMMM Do YYYY, h:mm');

      return {
        ...state,
        answerTimeCreatedAtMoment: action.payload,
        isEditingAnswerTimeAndCreatedAtHasChanged: state.initialAnswerTimeCreatedAtMoment && initialDate !== newDate
      };
    }

    default:
      return state;
  }
};
