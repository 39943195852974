const INITIAL_STATE = {
  combinedAnswerTimes: []
};

export const clientDashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_COMBINED_ANSWER_TIMES':
      return { ...state, combinedAnswerTimes: action.payload };

    default:
      return state;
  }
};
