import React from 'react';

// Component that is rendered when application crashes
function ErrorFallback() {
  return (
    <div className="pt-4">
      <h4 className="text-center font-weight-bold pt-4">Jotakin meni vikaan... / Something went wrong... </h4>
      <p className="text-center text-lg">Virhe kirjattu / Error logged</p>
    </div>
  );
}

export default ErrorFallback;
