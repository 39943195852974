import api from 'api';

export const startLogging = () => {
  window.addEventListener('unhandledrejection', (e) => e.reason?.message !== 'Network Error' && logError(e.reason));

  window.addEventListener('error', (e) => e.message !== 'Network Error' && logError(e));
};

const makeErrorString = (error) => {
  const enumerableError = {};

  enumerableError.userAgent = navigator.userAgent;
  enumerableError.locationHref = window.location.href;

  // Gather the non-enumerable properties of Error, and strip its toJSON method
  // to include everything in stringification.
  Object.getOwnPropertyNames(error).forEach((key) => {
    if (key !== 'toJSON') enumerableError[key] = error[key];
  });

  const errorString = JSON.stringify(enumerableError);

  return stripSensitiveData(errorString);
};

const makeErrorStringErrorBoundary = (error, info) => {
  const errorObject = {};
  errorObject.userAgent = navigator.userAgent;
  errorObject.locationHref = window.location.href;
  errorObject.details = error.toString();
  errorObject.stack = error.stack;
  errorObject.componentStack = info?.componentStack;

  const errorString = JSON.stringify(errorObject);

  return stripSensitiveData(errorString);
};

// This helps to prevent passwords and such from being logged.
const stripSensitiveData = (str) => {
  const placeholder = '(stripped_from_log)';

  return str
    .replaceAll(/password=[^&"]+/gi, `password=${placeholder}`)
    .replaceAll(/"password":".+?"/gi, `"password":"${placeholder}"`)
    .replaceAll(/"Authorization":".+?"/gi, `"Authorization":"${placeholder}"`);
};

export const logError = (error) => {
  console.log(error);

  const applog = {
    log: makeErrorString(error),
    source: 1,
    statusCode: error.response?.status
  };

  api.post('/api/ApplicationLogs', applog, { headers: { Authorization: 'secretApplicationLoggingKey' } }).catch((e) => {
    console.error('Failed to send the error log:', e);
  });
};

export const logErrorBoundary = (error, info) => {
  const applog = {
    log: makeErrorStringErrorBoundary(error, info),
    source: 1,
    statusCode: error.response?.status
  };

  api.post('/api/ApplicationLogs', applog, { headers: { Authorization: 'secretApplicationLoggingKey' } }).catch((e) => {
    console.error('Failed to send the error log:', e);
  });
};
