import { stateForFetch, makeFetchHandlersFor } from './shared/commonCrud.js';

const INITIAL_STATE = {
  ...stateForFetch
};

const actionHandlers = {
  ...makeFetchHandlersFor('SUBRESULT_AVERAGES'),
  ...makeFetchHandlersFor('EFFECTIVENESS')
};

export const reportReducer = (state = INITIAL_STATE, action) => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};
