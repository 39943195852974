const INITIAL_STATE = {
  // This is the organization user who's currently using the
  // dashboard. I.e. it's the logged-in employee.
  currentOrganizationUserId: null,
  currentOrganizationId: null
};

export const dashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_CURRENT_ORGANIZATION_USER_ID':
      return { ...state, currentOrganizationUserId: action.payload };
    case 'SET_CURRENT_ORGANIZATION_ID':
      return { ...state, currentOrganizationId: action.payload };
    default:
      return state;
  }
};
