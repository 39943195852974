import { lastMomentOfDay } from 'dashboard/utilities/miscHelpers';

const INITIAL_STATE = {
  selectedServiceIds: [],
  selectedQuestionnaireIds: [],
  organizationAnalytics: null,
  serviceAnalytics: null,
  serviceReport: null,
  serviceQuestionnaireReport: null,
  questionnaireAnalytics: null,
  activeTab: 'organization',
  selectedQuestionnaireId: null,
  includeAnonymous: true,
  includeNamed: true,
  showDataTables: false,
  selectedServiceQuestionnaireReportType: 'baseline',
  selectedServiceComparisonReportType: 'baseline',
  selectedServiceUserIds: [],
  dateRange: {
    startDate: new Date(2020, 0),
    endDate: lastMomentOfDay(),
    key: 'selection'
  }
};

export const analyticsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_SELECTED_SERVICE_IDS':
      return {
        ...state,
        selectedServiceIds: action.payload
      };
    case 'SET_SELECTED_QUESTIONNAIRE_IDS':
      return {
        ...state,
        selectedQuestionnaireIds: action.payload
      };
    case 'SET_ORGANIZATION_ANALYTICS':
      return {
        ...state,
        organizationAnalytics: action.payload
      };
    case 'SET_SERVICE_ANALYTICS':
      return {
        ...state,
        serviceAnalytics: action.payload
      };
    case 'SET_QUESTIONNAIRE_ANALYTICS':
      return {
        ...state,
        questionnaireAnalytics: action.payload
      };
    case 'SET_ANALYTICS_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.payload
      };
    case 'SET_SELECTED_QUESTIONNAIRE_ID':
      return {
        ...state,
        selectedQuestionnaireId: action.payload
      };
    case 'SET_ANALYTICS_INCLUDE_ANONYMOUS':
      return {
        ...state,
        includeAnonymous: action.payload
      };
    case 'SET_ANALYTICS_INCLUDE_NAMED':
      return {
        ...state,
        includeNamed: action.payload
      };
    case 'SET_SELECTED_SERVICE_USER_IDS':
      return {
        ...state,
        selectedServiceUserIds: action.payload
      };
    case 'SET_DATE_RANGE':
      return {
        ...state,
        dateRange: action.payload
      };
    case 'SET_SERVICE_REPORT':
      return {
        ...state,
        serviceReport: action.payload
      };
    case 'SET_SERVICE_QUESTIONNAIRE_REPORT':
      return {
        ...state,
        serviceQuestionnaireReport: action.payload
      };
    case 'SET_SHOW_DATA_TABLES':
      return {
        ...state,
        showDataTables: action.payload
      };
    case 'SET_SELECTED_SERVICE_QUESTIONNAIRE_REPORT_TYPE':
      return {
        ...state,
        selectedServiceQuestionnaireReportType: action.payload
      };
    case 'SET_SELECTED_SERVICE_COMPARISON_REPORT_TYPE':
      return {
        ...state,
        selectedServiceComparisonReportType: action.payload
      };
    default:
      return state;
  }
};
