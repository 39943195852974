import AuthenticationProvider from 'AuthenticationProvider';
import React, { lazy } from 'react';

const ClientDashboard = lazy(() => import('./ClientDashboard'));

/// Workaround to prevent loading bundle twice at startup
function ClientDashboardWrapper() {
  return (
    <AuthenticationProvider>
      <ClientDashboard />
    </AuthenticationProvider>
  );
}

export default ClientDashboardWrapper;
