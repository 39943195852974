const INITIAL_STATE = {
  language: null
};

export const appReducer = (currentState = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'APP_LANGUAGE_WAS_SET':
      document.documentElement.lang = action.language;

      return {
        ...currentState,
        language: action.language
      };

    default:
      return currentState;
  }
};
