import { createSelector } from 'reselect';
import { memoize } from 'lodash-es';
import * as schemas from './schemas';

export const equals = (a, b) => a === b;

// This selects an array of entities from the store, filtered by the value of a property.
// The filter uses the comparator function to compare the property value.
// The result array is memoized so that the exact same array object is always returned when
// the parameters given to this function are the same; otherwise a new array is computed.
export const select = memoize(
  (schema, propertyName, comparator, propertyValue) =>
    createSelector(
      (state) => state.entities[schema._key],
      (entities) => {
        //console.log('recompute',schema._key, propertyName, comparator.name, propertyValue)
        const ret = entities ? Object.values(entities).filter((o) => comparator(o[propertyName], propertyValue)) : [];
        return ret;
      }
    ),
  // Generate the cache key for memoize.
  (schema, propertyName, comparator, propertyValue) =>
    `${schema._key}-${propertyName}-${comparator.name}-${propertyValue}`
);

export const selectAll = memoize(
  (schema) =>
    createSelector(
      (state) => state.entities[schema._key],
      (entities) => (entities ? Object.values(entities) : [])
    ),
  // Use the schema key as cache key.
  (schema) => schema._key
);

export const selectById = (schema, id) => (state) => state.entities[schema._key] && state.entities[schema._key][id];

export const selectFirst = (schema, predicate) => (state) =>
  state.entities[schema._key] && Object.values(state.entities[schema._key]).find(predicate);

export const selectAllQuestionsInQuestionnaire = (questionnaireId) => (state) => {
  const questionGroups = select(schemas.QuestionGroup, 'questionnaireId', equals, questionnaireId)(state);

  const allQuestions = selectAll(schemas.Question)(state);

  return allQuestions.filter((q) => questionGroups.some((qg) => qg.id === q.questionGroupId));
};

export const clearSelectorCaches = () => {
  select.cache.clear();
  selectAll.cache.clear();
};
