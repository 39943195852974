import { authContext } from 'auth';
import axios from 'axios';
import { store } from 'store';

// Create a base instance of Axios for making http requests to our API.
let api = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  baseURL: process.env.REACT_APP_BACKEND
});

api.interceptors.request.use(async (request) => {
  if (authContext?.refreshToken) await keepSessionFresh();

  if (authContext?.token && !request.headers.Authorization)
    request.headers.Authorization = `Bearer ${authContext.token}`;

  addIdHeadersToRequest(request);

  return request;
});

// Refresh the session token if the access token has expired or will expire soon.
const keepSessionFresh = async () => {
  try {
    await authContext.updateToken();
  } catch (e) {
    console.log('Failed to refresh the session:', e);

    // We failed to refresh the session, so the user must log in again.
    authContext.logout();
  }
};

const addIdHeadersToRequest = (request) => {
  const organizationId = store.getState().dashboard?.currentOrganizationId;
  const serviceId = store.getState().serviceView?.serviceId;

  if (organizationId) request.headers['OrganizationId'] = organizationId;

  if (serviceId) request.headers['ServiceId'] = serviceId;
};

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 440) {
      // 440 Login Time-out
      authContext.logout();

      // Return a promise that never resolves. This prevents the 440 response
      // from being passed to the response handlers that have no error checking
      // and would crash and briefly show ugly errors to the user.
      return new Promise(() => {});
    }

    return Promise.reject(error);
  }
);

export default api;
