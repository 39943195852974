const INITIAL_STATE = {};

// This reducer updates the status of a backend request.
// The request must have a name (action.props.options.requestName),
// otherwise it will be ignored here.
export const requestReducer = (state = INITIAL_STATE, action) => {
  if (action.type === 'CLEAR_REQUEST_STATUS') {
    let newState = { ...state };
    delete newState[action.payload];
    return newState;
  }

  const requestName = action.props?.options?.requestName;

  if (!requestName) return state;

  let newStatus;

  switch (action.type) {
    case 'API_GET_REQUEST':
    case 'API_POST_REQUEST':
    case 'API_PUT_REQUEST':
    case 'API_DELETE_REQUEST':
      newStatus = 'request';
      break;

    case 'API_GET_SUCCESS':
    case 'API_POST_SUCCESS':
    case 'API_PUT_SUCCESS':
    case 'API_DELETE_SUCCESS':
      newStatus = 'success';
      break;

    case 'API_GET_FAILED':
    case 'API_POST_FAILED':
    case 'API_PUT_FAILED':
    case 'API_DELETE_FAILED':
      newStatus = 'failed';
      break;

    default:
      newStatus = undefined;
  }

  if (newStatus) {
    return {
      ...state,
      [requestName]: {
        name: requestName,
        status: newStatus,
        action: action,
        data: action.payload
      }
    };
  }

  return state;
};
