import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';

import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/paper-dashboard.scss?v=1.2.0';
import './assets/scss/hyvio.scss';

import { store } from './store';
import { startLogging } from 'logging';
import { logErrorBoundary } from 'logging';
import ErrorFallback from 'components/ErrorFallback';

import AdminDashboardWrapper from 'dashboard/layouts/AdminDashboardWrapper';
import ClientDashboardWrapper from 'clientDashboard/ClientDashboardWrapper';
const JoinServiceAsRegisteredUser = lazy(() => import('publicPages/layouts/JoinServiceAsRegisteredUser'));
const LoginRedirector = lazy(() => import('publicPages/layouts/LoginRedirector'));
const Registration = lazy(() => import('./publicPages/layouts/Registration'));
const JoinPage = lazy(() => import('./publicPages/layouts/JoinPage'));
const Anonymous = lazy(() => import('publicPages/layouts/Anonymous'));
const PublicAnswerLinkDirector = lazy(() => import('publicPages/layouts/PublicAnswerLinkDirector'));

function App() {
  const subdomain = getSubDomain();

  let isProductionEnv = isProductionEnvironment();
  let productionDashboard = false;
  let productionClients = false;

  if (isProductionEnv) {
    if (subdomain === 'pro') {
      productionDashboard = true;
    } else if (subdomain === 'app') {
      productionClients = true;
    }
  }

  // This should be done early during page load to avoid language misdetection
  // by the browser.
  initializeDocumentLanguage();

  return (
    <ErrorBoundary onError={logErrorBoundary} FallbackComponent={ErrorFallback}>
      <Suspense fallback={null}>
        {productionDashboard && (
          <Provider store={store}>
            <BrowserRouter>
              <Routes>
                <Route path="/*" element={<AdminDashboardWrapper />} />
              </Routes>
            </BrowserRouter>
          </Provider>
        )}
        {productionClients && (
          <BrowserRouter>
            <Provider store={store}>
              <Routes>
                <Route exact path="/register" element={<Registration />} />
                <Route exact path="/joinPage" element={<JoinPage />} />
                <Route exact path="/joinServiceAsRegisteredUser/:id" element={<JoinServiceAsRegisteredUser />} />
                <Route exact path="/linkedAnswer/:serviceQuestionnaireId" element={<PublicAnswerLinkDirector />} />
                <Route exact path="/LoginRedirect/:redirectUrl" element={<LoginRedirector />} />

                <Route exact path="/anon/:serviceQuestionnaireId" element={<Anonymous />} />
                <Route exact path="/anon/:serviceQuestionnaireId/embed" element={<Anonymous embed />} />

                <Route path="/*" element={<ClientDashboardWrapper />} />
              </Routes>
            </Provider>
          </BrowserRouter>
        )}
        {!isProductionEnv && (
          <BrowserRouter>
            <Provider store={store}>
              <Routes>
                <Route exact path="/register" element={<Registration />} />
                <Route exact path="/joinPage" element={<JoinPage />} />
                <Route exact path="/joinServiceAsRegisteredUser/:id" element={<JoinServiceAsRegisteredUser />} />
                <Route exact path="/linkedAnswer/:serviceQuestionnaireId" element={<PublicAnswerLinkDirector />} />
                <Route exact path="/LoginRedirect/:redirectUrl" element={<LoginRedirector />} />

                <Route path="/admin/*" element={<AdminDashboardWrapper />} />

                <Route exact path="/anon/:serviceQuestionnaireId" element={<Anonymous />} />
                <Route exact path="/anon/:serviceQuestionnaireId/embed" element={<Anonymous embed />} />

                <Route path="/*" element={<ClientDashboardWrapper />} />
              </Routes>
            </Provider>
          </BrowserRouter>
        )}
      </Suspense>
    </ErrorBoundary>
  );
}

// This sets the document language based on the saved language preference.
// If there's no saved preference, then use the browser language if it's one
// of the supported languages; otherwise, default to english.
const initializeDocumentLanguage = () => {
  const supportedLanguages = ['fi', 'en'];

  const navigatorLanguageTag = window.navigator.userLanguage || window.navigator.language;

  const navigatorLanguage = navigatorLanguageTag.split('-')[0];

  const validLanguage = (supportedLanguages.includes(navigatorLanguage) && navigatorLanguage) || 'en';

  const savedLanguage = localStorage.getItem('appLanguage');

  if (!savedLanguage) localStorage.setItem('appLanguage', validLanguage);

  document.documentElement.lang = savedLanguage || validLanguage;
};

export function isProductionEnvironment() {
  const subdomain = getSubDomain();
  return subdomain !== null && (subdomain === 'pro' || subdomain === 'app');
}

function getSubDomain() {
  let host = window.location.host;
  let parts = host.split('.');
  // If we >=3 parts, then we have a subdomain
  if (parts.length >= 3) {
    return parts[0];
  } else {
    return null;
  }
}

// The admin path is the root path of the dashboard. The environment provides
// the path via the url defined by REACT_APP_FRONTEND_DASHBOARD.
export function getAdminPath() {
  const url = new URL(process.env.REACT_APP_FRONTEND_DASHBOARD);

  return url.pathname;
}

export function getClientDashboardPath() {
  const url = new URL(process.env.REACT_APP_FRONTEND_CLIENTS);

  return url.pathname;
}

// This is a workaround for the situation where the browser mutates the
// DOM tree directly, causing React to crash as it references a node
// that has disappeared from the DOM tree.
// Source https://github.com/facebook/react/issues/11538#issuecomment-417504600
const applyWorkaroundForDomMutationCrash = () => {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        console.log(
          'Prevented removeChild crash due to DOM mutation: https://github.com/facebook/react/issues/11538#issuecomment-390386520'
        );
        return child;
      }
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        console.log(
          'Prevented insertBefore crash due to DOM mutation: https://github.com/facebook/react/issues/11538#issuecomment-390386520'
        );
        return newNode;
      }
      return originalInsertBefore.apply(this, arguments);
    };
  }
};

startLogging();

applyWorkaroundForDomMutationCrash();

const root = createRoot(document.getElementById('root'));
root.render(<App />);
